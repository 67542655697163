
import Spinner from "../common/spinner/spinner";
import styles from "../components/product/product-header/product-header.module.css";
import AuthGuard from "../guards/auth-guard";

const DashProducaoStage = () => {
  return (
    <AuthGuard
      allow="greener"
      notAllowMessage={
        <div className="container" style={{ padding: "60px 0 180px 0" }}>
          <h3 className={"title-h3 text-bold"}>
            Página exclusiva para administradores
          </h3>
        </div>
      }
    >
      <>
        <div>
          <iframe
            src="https://dash-qa-request.yellowrock-f370c7b1.brazilsouth.azurecontainerapps.io/"
            frameBorder="0"
            style={{
              width: "100%",
              height: "100vh",
              border: "3px solid #F2F4F2",
            }}
          ></iframe>
        </div>
      </>
    </AuthGuard>
  );
};

export default DashProducaoStage;
